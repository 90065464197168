




import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Route } from 'vue-router';

import { NavGuard } from '../../utils/decorators';
import { company } from '../../store/modules/company-module';
import { user } from '../../store/modules/user-module';

@Component
export default class OAuth extends Vue {
  @NavGuard
  public async beforeRouteEnter(to: Route, _from: Route, next: Function) {
    // Check required query params
    const { code, state } = to.query;
    if (Array.isArray(code) || !code) return next('/404');
    if (Array.isArray(state) || !state) return next('/404');

    // Display loader on first render
    let that: any = undefined;
    next((vm: any) => {
      that = vm;
      vm.$app.loader(vm.$t('sign-in-dialog'));
    });

    // Sign in
    await user.signIn({ code });

    // Continue to research or home page
    next(state === '/' ? `/c/${company.id}/research` : state);
    that.$app.loader();
  }
}
